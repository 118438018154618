import React, { useState } from 'react';
import { fetchWeather } from './api/fetchWeather';
import './App.css';

const App = () => {
	const [query, setQuery] = useState('');
	const [weather, setWeather] = useState({});

	const search = async (e) => {
		if (e.key === 'Enter') {
			const data = await fetchWeather(query);

			console.log(data);
			setWeather(data);
			setQuery('');
		}
	};

	const windDir = (deg) => {
		if (deg > 11.25 && deg < 33.75) {
			return 'N-NE';
		} else if (deg > 33.75 && deg < 56.25) {
			return 'E-NE';
		} else if (deg > 56.25 && deg < 78.75) {
			return 'E';
		} else if (deg > 78.75 && deg < 101.25) {
			return 'E-SE';
		} else if (deg > 101.25 && deg < 123.75) {
			return ' E-SE';
		} else if (deg > 123.75 && deg < 146.25) {
			return 'SE';
		} else if (deg > 146.25 && deg < 168.75) {
			return 'S-SE';
		} else if (deg > 168.75 && deg < 191.25) {
			return 'S';
		} else if (deg > 191.25 && deg < 213.75) {
			return 'S-SW';
		} else if (deg > 213.75 && deg < 236.25) {
			return 'SW';
		} else if (deg > 236.25 && deg < 258.75) {
			return 'W-SW';
		} else if (deg > 258.75 && deg < 281.25) {
			return 'W';
		} else if (deg > 281.25 && deg < 303.75) {
			return 'W-NW';
		} else if (deg > 303.75 && deg < 326.25) {
			return 'NW';
		} else if (deg > 326.25 && deg < 348.75) {
			return 'N-NW';
		} else {
			return 'N';
		}
	};

	return (
		<div className='main-container'>
			<input
				type='text'
				className='search'
				placeholder='Location...'
				value={query}
				onChange={(e) => setQuery(e.target.value)}
				onKeyPress={search}
			/>
			{weather.main && (
				<div className='city'>
					<h2 className='city-name'>
						<span>{weather.name}</span>
						<sup>{weather.sys.country}</sup>
					</h2>
					<div className='city-temp'>
						{Math.round(weather.main.temp)}
						<sup>&deg;C</sup>
					</div>
					<p>Feels like {Math.round(weather.main.feels_like)} &deg;C</p>
					<div className='info'>
						<img
							className='city-icon'
							src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
							alt={weather.weather[0].description}
						/>
						<p>{weather.weather[0].description}</p>
						<p>
							Min Temp {Math.round(weather.main.temp_min)} &deg;C
							<br />
							Max Temp {Math.round(weather.main.temp_max)} &deg;C
							<br />
							Humidity {weather.main.humidity} %
							<br />
							Pressure {weather.main.pressure} millibars
							<br />
							Wind {weather.wind.speed} mph {windDir(weather.wind.deg)} -{' '} {weather.wind.deg}&deg;
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default App;
